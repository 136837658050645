body{
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-color: #f0f4f8;
}

.AzulEscuro{
    background-color: #172531;
    color: #ffffff;
}

.AzulMedio{
    background-color: #2e4a62  ;
    color: #ffffff;
}

.AzulClaro{
    background-color: #456f93 ;
    color: #ffffff;
}

.AzulSimples{
    background-color: #cddbe7;
}

.AzulPale{
    background-color: #f0f4f8;
    color: #000000;
}

.bnt{
    width: 25px;
    height: 25px;
    margin-left: 2px;
    align-items: center;
    justify-items: center;
    float: left;
    text-align: center;
    text-justify: inherit;
}